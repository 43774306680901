<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "mega-containers",
              "select-access-type",
              "select-access-type"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="clebex-item-section"
      v-if="
        containerAccessTypes &&
          containerAccessTypes.data &&
          containerAccessTypes.data.length
      "
    >
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="type in containerAccessTypes.data"
        :key="type.id"
      >
        <router-link
          class="clebex-item-content-wrapper"
          :to="{
            name: 'r_edit-mega-container-select-container',
            params: {
              ...$route.params,
              access_type_id: type.id
            }
          }"
        >
          <span class="label">{{ type.name }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </router-link>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
export default {
  name: "SelectContainerAccessTypes",
  computed: {
    ...mapState("containers", ["containerAccessTypes"])
  },
  created() {
    if (!this.containerAccessTypes) {
      this.getContainerAccessTypes();
    }
  },
  methods: {
    ...mapActions("containers", [
      "setSelectedContainerAccessType",
      "getContainerAccessTypes"
    ]),
    selectAccessType(type) {
      this.setSelectedContainerAccessType(type);
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
